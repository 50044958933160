import { useTranslate } from '/machinery/I18n'
import { useFirebaseApp } from '/machinery/FirebaseAppProvider'
import { useReportError } from '/machinery/ReportError'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { useSpotlerForm } from '/machinery/useSpotlerForm'

import { FormSuccess } from '/features/pageOnly/FormSuccess'
import { FormSection } from '/features/pageOnly/FormSection'
import { FormFieldText, FormFieldEmail, FormFieldCheckBoxWithValue } from '/features/buildingBlocks/FormField'
import { ButtonSubmit } from '/features/buildingBlocks/Button'
import { FormError } from '/features/buildingBlocks/FormError'

export function FormSpotler({ title, spotlerId, spotlerEncryptionId, fields: cmsFields, submitButtonText, successMessage, styleContext }) {
  const { __ } = useTranslate()

  const firebaseApp = useFirebaseApp()
  const reportError = useReportError()

  const isEcommerceCheckoutEventTrackedRef = React.useRef(false)

  const {
    fields,
    submit,
    isSuccess,
    isPending,
    error
  } = useSpotlerForm({
    cmsFields,
    spotlerId,
    spotlerEncryptionId,
    reportError,
    firebaseApp,
    onSuccess: handleSuccess
  })

  return (
    <>
      {isSuccess ? (
        <FormSuccess
          title={successMessage?.title ?? __`spotler-form-sent`}
          description={successMessage?.description ?? __`spotler-form-sent-description`}
          {...{ styleContext }}
        />
      ) : (
        <FormSection
          dataX='application-form'
          onSubmit={submit}
          onChange={handleFormChange}
          {...{ title, styleContext }}
        >
          <FormFields {...{ fields, cmsFields }} />

          <ButtonSubmit dataX='submit-application-form' label={submitButtonText ?? __`form-label-submit`} {...{ isPending }} />

          {error && <FormError id='form-submit-error' errorMessage={__`form-submit-error`} />}
        </FormSection>
      )}
    </>
  )

  function handleFormChange(event) {
    if (isEcommerceCheckoutEventTrackedRef.current) return

    if (event.target.value?.length > 2) {
      pushToDataLayer({ event: 'form_start' })
      isEcommerceCheckoutEventTrackedRef.current = true
    }
  }

  function handleSuccess() {
    pushToDataLayer({ event: 'form_submit' })
  }
}

function FormFields({ fields, cmsFields }) {
  return cmsFields?.map(({ _type, spotlerId, label, required }, i) => {
    switch (_type) {
      case 'fieldString': return (
        <FormFieldText key={i} field={fields[spotlerId]} {...{ label, required }} />
      )
      case 'fieldCheckbox': return (
        <FormFieldCheckBoxWithValue key={i} field={fields[spotlerId]} instanceValue={label}>{label}</FormFieldCheckBoxWithValue>
      )
      case 'fieldEmail': return (
        <FormFieldEmail key={i} field={fields[spotlerId]} {...{ label, required }} />
      )
      default: {
        reportError(new Error(`Unknown content type '${_type}'`))
        return null
      }
    }
  })
}

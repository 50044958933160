import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { FirebaseAppProvider } from '/machinery/FirebaseAppProvider'
import { useClientConfig } from '/machinery/ClientConfig'

import { FormSpotler } from './FormSpotler'


export default function FormSpotlerApp({ title, spotlerId, spotlerEncryptionId, fields, submitButtonText, successMessage, styleContext }) {
  const config = useClientConfig()
  const queryClient = new QueryClient()

  return (
    <FirebaseAppProvider config={config.firebase} name='form-event'>
      <QueryClientProvider client={queryClient}>
        <FormSpotler {...{ title, spotlerId, spotlerEncryptionId, fields, submitButtonText, successMessage, styleContext }} />
      </QueryClientProvider>
    </FirebaseAppProvider>
  )
}

import { signInAnonymously, getAuth } from 'firebase/auth'
import { getDatabase, push, ref, serverTimestamp } from 'firebase/database'
import { useMutation } from '@tanstack/react-query'
import { useForm } from '@kaliber/forms'
import { required, email, optional } from '@kaliber/forms/validation'

import { useLanguage } from '/machinery/I18n'

export function useSpotlerForm({ cmsFields, spotlerId, spotlerEncryptionId, reportError, firebaseApp, onSuccess }) {
  const language = useLanguage()

  const { mutate, isPending, isSuccess, error } = useMutation({
    mutationFn: handleSendForm,
    onError: e => { reportError(e) },
    onSuccess: handleMutationSuccess
  })

  const formFieldsInitialValues = cmsFields?.reduce((acc, cur) => ({
    ...acc,
    [cur.spotlerId]: (cur.type === 'boolean') ? false : ''
  }), {})

  const formFields = cmsFields?.reduce((acc, cur) => ({
    ...acc,
    [cur.spotlerId]: [cur.required ? required : optional, cur.type === 'email' && email].filter(Boolean)
  }), {})

  const { form: { fields }, submit } = useForm({
    initialValues: formFieldsInitialValues,
    fields: formFields,
    onSubmit: handleSubmit,
  })

  function handleSubmit({ value, invalid }) {
    if (invalid) return
    mutate({ formValues: value })
  }

  function handleMutationSuccess() {
    if (onSuccess) onSuccess()
  }

  async function handleSendForm({ formValues }) {
    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))
    const url = window.location.href

    const formSubmission = {
      formSubmitDate: serverTimestamp(),
      uid,
      url,
      formId: spotlerId,
      formEncId: spotlerEncryptionId,
      formValues,
      language,
    }

    await push(ref(getDatabase(firebaseApp), 'services/form-processing-service/queue'), formSubmission)
  }

  return {
    fields,
    submit,
    isSuccess,
    isPending,
    error
  }
}
